import React from 'react'
import Link from '../Link'

import * as S from './styled'

export default ({to, children, ...props}) => (
  <Link to={to}>
    <S.Button {...props}>{children}</S.Button>
  </Link>
)
