import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import Button from '../components/Button'

import * as S from '../styles/404'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" description="Página não encontrada" />
    <S.ContainerText>
      <h1>NOT FOUND</h1>
      <p>Não encontramos essa página.</p>
      <Button to="/">Voltar para HOME</Button>
    </S.ContainerText>
  </Layout>
)

export default NotFoundPage
